import { TwitterLogoIcon } from "@radix-ui/react-icons";

export const Footer = () => {
  return (
    <footer className="mt-20 bg-white rounded-lg shadow dark:bg-gray-900 ">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://www.osianovel.com/"
            className=" flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img
              src="/logo_app.webp"
              className="rounded h-8"
              alt="Osia Novel Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Osia Novel
            </span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            {/* <li>
              <a href="#" className="hover:underline me-4 md:me-6">
                About
              </a>
            </li> */}
            <li className="flex items-center">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://x.com/osianovel"
                className="hover:underline me-4 md:me-6"
              >
                <TwitterLogoIcon />
              </a>
            </li>
            <li>
              <a href="/privacy" className="hover:underline me-4 md:me-6">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms" className="hover:underline me-4 md:me-6">
                Terms of Service
              </a>
            </li>
            {/* <li>
              <a href="#" className="hover:underline me-4 md:me-6">
                Licensing
              </a>
            </li> */}
            <li>
              <a href="/contact" className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2024 Osia Novel . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};
